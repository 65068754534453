import router from "@/router";
import axios from "axios";
// import store from "../store/index";

axios.interceptors.request.use(config => {
    config.baseURL = `${process.env.VUE_APP_BASE_URL}/`,
        // config.headers.Authorization = ` Bearer ${localStorage.getItem('token') !== null ? localStorage.getItem('token') : false} `
        config.headers.Authorization = `Bearer ${ localStorage.getItem('auth_info') !== null && localStorage.getItem('auth_info') !== '[object Object]' ? JSON.parse(localStorage.getItem('auth_info'))?.token : ''}`

    return config;
})

axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response.status === 401) {
            axios.post('auth/guest-user', { refreshToken: localStorage.getItem('refreshToken') })
            .then(res => {
                localStorage.setItem('auth_info', JSON.stringify(res.data))
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
            })
    }
    if (error.response.status === 403) {
        router.push('/')
    }
    // if (error.response.status === 500) {
    //     router.push('/')
    // }
    return Promise.reject(error);
})

export default axios;